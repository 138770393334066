import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from 'react-cloudinary-lazy-image'
import SwiperJS from '../components/SwiperJS';

const ProjectCategorieTemplate = ({ data, pageContext }) => {

  const { projectData, categorieData } = data;
  const projects = projectData.edges.map(m => m.node.frontmatter)
  if (projects.length) {
  const projectFields = projects[0]

  const otherProjects = projects.slice(1)
  const categorie = categorieData.frontmatter;

  const swiperFields = projectFields.photos?.filter(f => f.slider === "1").sort((a, b) => a.position - b.position).map(m => ({
    url: projectFields.url,
    nom: projectFields.nom,
    photo: m.photo,
    categorie: categorie
  }))

  if (projectFields) {
    return (
      <Layout pageContext={pageContext}>
        <Fragment>
          <section className="grille">
            <div className="row no-gutters">
              <div className="col" id="vignette" >
                <SwiperJS swiperFields={swiperFields} naviguable={true}></SwiperJS>   
              </div>
            </div>
            <div className="row no-gutters pb-5">
              {otherProjects.length !== 0 &&
                otherProjects.map((project, i) => {
                  const photo = project?.photos?.find(f => f.slider === "1")?.photo;
                  if (photo) {
                    var imgSegment = photo.split('/');
                    var imgFile = imgSegment.pop();
                    var imgFolder = imgSegment.pop();
                    return (
                      <div key={i} className="col-md-6 col-xl-4 p-0 category-home-vignette">
                        <a href={"/projets/" + categorie.url + "/" + project.url}>
                        <div className={"d-inline-block w-100 category-home-header " + project.url}>
                            <h3 className="mx-4 mt-3">
                              <span className={"other-projet-nom-label " + project.url}>{project.nom}</span>
                            </h3>
                          </div>
                          <div className="category-home-img h-100">
                          <Img
                            cloudName={'groupea'}
                            imageName={'projets/' + imgFolder + '/' + imgFile}
                            fluid={{
                              maxWidth: 625
                            }}
                            alt={imgFile}
                            quality={false}
                          />
                          </div>
                          </a>
                      </div>
                    )
                  } else {
                    return(<div>
                       <Img
                        cloudName={'groupea'}
                        imageName='no-image.png'
                        fluid={{
                          maxWidth: 624
                        }}                      
                        alt={imgFile}
                        quality={false}
                      />
                      </div>)
                  }
                })
              }
            </div>
          </section>
        </Fragment>
        </Layout>
    )
  } else {
    return (<Layout>Aucun projet dans cette catégorie</Layout>)
  }
}
else {
  return (<Layout>Aucun projet dans cette catégorie</Layout>)
}
}

export default ProjectCategorieTemplate

export const CategorieProjectsQuery = graphql`
    query ProjectsByCategory($url: String!) {
      categorieData: markdownRemark(frontmatter: {collectionKey: {eq: "categories"}, url: {eq: $url}}) {
        frontmatter {
          collectionKey
          url
          nom            
        }
      }
      projectData: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "projets"}, categorie: {eq: $url}}}, sort: {fields: [frontmatter___position], order: ASC}) {
        edges {
          node {
            id
            frontmatter {
              url
              nom
              position
              categorie
              photos {
                  position
                  photo
                  slider
                }            
            }
          }
        }
      }
  }
`
